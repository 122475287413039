import React from 'react';
import './Popup.css';

function Popup({ closePopup }) {

    // Función para manejar el click en la imagen
    const handleImageClick = () => {
    // Buscar el elemento del formulario y desplazar la página hacia él
    const formSection = document.getElementById('formulario');
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth' });
      closePopup(); // Cerrar el popup después de hacer clic en la imagen
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <img
          className='popup-img'
          src="/media/POP-UP-1.jpg"
          alt="Descripción de la imagen"
          onClick={handleImageClick} // Agregar el evento onClick en la imagen
        />
      </div>
    </div>
  );
}

export default Popup;
